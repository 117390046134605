<template>
  <div class="coaching-list-container">
    <!-- content-section -->
    <section class="content-section">
      <header class="section-header">
        <nav class="nav kb-nav-dot">
          <template v-for="(sub, index) in subTab.tabs" :key="index">
            <a href="javascript:" class="kb-nav-link" :class="{'is-active': subTab.current === sub}" @click="changeSub(sub)"><span class="title font-bodyB">{{ sub }}</span></a>
          </template>
        </nav>
      </header>
      <div class="list-top list-top-divider list-top-shrink">
        <div class="top-column">
          <div class="list-sort">
            <!-- sort -->
            <div class="sort">
              <SortButtonSelect2
                  v-model="filters.goal"
                  v-model:toggle="toggles.goal"
                  title="목표 등록 여부"
                  :options="[{display: '목표 등록완료', value: 'Y'}, {display: '목표 미등록', value: 'N'}]"
                  sequence-key="value"
                  name-key="display"
                  :is-all="true"
                  offset="main-header"
                  @update:toggle="closeToggles(toggles, 'goal')"
                  @selected="searchItems"
              />

            </div>
            <!-- //sort -->
            <!-- sort -->
            <div class="sort">
              <!-- dropdown -->
              <!-- NOTE: is-active로 on/off 제어 -->
              <!-- <div class="dropdown is-active"> -->
              <SortButtonSelect2
                  v-model="filters.coaching"
                  v-model:toggle="toggles.coaching"
                  title="코칭 등록 여부"
                  :options="[{display: '코칭 등록완료', value: 'Y'}, {display: '코칭 미등록', value: 'N'}]"
                  sequence-key="value"
                  name-key="display"
                  :is-all="true"
                  offset="main-header"
                  @update:toggle="closeToggles(toggles, 'coaching')"
                  @selected="searchItems"
              />
            </div>
            <!-- //sort -->
          </div>
        </div>
        <div class="top-column">
          <p class="text text-muted">총 {{targetItems.length}}명</p>
        </div>
      </div>
      <div class="board-list-container board-list-bordered">
        <ul class="board-list">
          <!-- board-list-item -->
          <li v-for="(item, index) in renderItems" :key="index" class="board-list-item">
            <div class="list-content">
              <div class="list-content-title">
                <h5 class="title">
                  <span class="text text-truncate">{{ item.lrnerNm }} ・ {{ item.deptNm }} ・ {{ item.jbgdCd && item.jbgdCd.length >= 2 ? item.jbgdCd.substr(0, 2) : '-' }} | {{ item.curtJbgdPromoYmd && item.curtJbgdPromoYmd.length >= 4 ? item.curtJbgdPromoYmd.substr(0, 4) : '-' }}</span>
                </h5>
              </div>
              <div class="list-meta-content">
                <span class="text" :class="{'text-red' : item.hopeAbility.crseWorkDtlSn === 0}">목표{{ item.hopeAbility.crseWorkDtlSn > 0 ? '등록' : '미등록' }}</span>
                <span class="text">희망역할 {{ item.hopeAbility.crseWorkDtlSn > 0 ? item.hopeAbility.crseWorkDtlNm : '-'}}</span>
                <span class="text">우수역할 {{ item.bestAbility.crseWorkDtlNm ? item.bestAbility.crseWorkDtlNm : '-' }}</span>
              </div>

              <div v-if="subTab.current === 'Coaching' && item.hopeAbility.crseWorkDtlSn > 0" class="list-content-buttons">
                <button class="kb-btn kb-btn-outline" @click="showCoaching(item)">관리</button>
              </div>
            </div>
          </li>
        </ul>

        <CommonPaginationFront :paging="{
                  pageNo: paging.pageNo,
                  pageSize: paging.pageSize,
                  totalCount: (targetItems.length)
                }" :page-func="pagingFunc"
                   :page-list-size="5"
        />
      </div>
    </section>
  </div>
</template>
<script>

import CommonPaginationFront from '@/components/CommonPaginationFront';
import SortButtonSelect2 from '@/components/common/SortButtonSelect2';
import {viceTalentCoachingSetup} from '@/assets/js/modules/mypage/vice/vice-setup';

export default {
  name: "MobileViceTalentCoaching",
  components: {SortButtonSelect2, CommonPaginationFront},
  props: {
    abilities: Array,
    goals: Array,
  },
  emits: ['showCoaching'],
  setup: viceTalentCoachingSetup
}
</script>
