<template>
  <div class="main-header">
    <!-- main-header > header-snb -->
    <div class="header-snb mb-0">
      <!-- [yyy:220926] 수정 -->
      <nav class="snb snb-fill">
        <ul class="snb-list">
          <template v-if="dept.upDeptNms">
            <template v-for="(upDeptNm, index) in dept.upDeptNms" :key="index">
              <li v-if="dept.deptNm" class="snb-item"><button>{{ upDeptNm }}</button></li>
            </template>
          </template>

          <li v-if="dept.deptNm" class="snb-item"><button>{{ dept.deptNm }}</button></li>

          <template v-if="dept && dept.models">
            <template v-for="(m, index) in dept.models" :key="index">
              <li class="snb-item" :class="{'snb-item-divider': index === 0}">
                <SortButtonSelect3
                    v-model="m.deptCd"
                    v-model:toggle="m.toggle"
                    title="전체"
                    :options="getDeptOptions(m, index)"
                    sequence-key="deptCd"
                    name-key="deptNm"
                    :is-all="true"
                    :is-num="true"
                    :width="260"
                    emptyText="부서없음"
                    button-class="snb-link"
                    @update:toggle="closeDeptToggle(index)"
                    @selected="selectedDept(index)"
                />
              </li>
            </template>
          </template>
        </ul>
      </nav>
    </div>

    <div class="header-tabs">
      <nav class="tab-list">
        <template v-for="(tab, index) in links" :key="index">
          <a href="javascript:" class="tab" :class="{'is-active' : tab.key === link}" @click="changeLink(tab.key)"><span class="text">{{ tab.name }}</span></a>
        </template>
      </nav>
    </div>
  </div>
</template>
<script>

import SortButtonSelect3 from '@/components/common/SortButtonSelect3';
import {viceRouteSetup} from '@/assets/js/modules/mypage/vice/vice-setup';

export default {
  name: "MobileViceTalentRoute",
  components: {SortButtonSelect3},
  props: {
    link: String,
    dept: Object,
    depts: Array,
  },
  emits: ['update:link', 'closeToggles', 'selectedDept'],
  setup: viceRouteSetup
}
</script>
