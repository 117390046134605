<template>
  <article class="content-section">
    <header class="section-header">
      <h3 class="title">역량개발 우수 학습분야</h3>
    </header>
    <div class="chart-wrap">
      <!-- chart -->
      <div class="chart chart-pie">
        <ToastNestedPieChart
            :categories="['우수역할']"
            :series="chartSeries"
            :options="chartOptions"
        />
      </div>
      <!-- chart-legends -->
      <div class="chart-legends">
        <div class="legend"><span class="marker marker-gray91"></span><span class="text">자산관리</span></div>
        <div class="legend"><span class="marker marker-silver"></span><span class="text">기업금융</span></div>
        <div class="legend"><span class="marker marker-gold"></span><span class="text">DT기획</span></div>
        <div class="legend"><span class="marker marker-yellow"></span><span class="text">DT개발</span></div>
        <div class="legend"><span class="marker marker-vso"></span><span class="text">IB</span></div>
        <div class="legend"><span class="marker marker-bb"></span><span class="text">자본시장</span></div>
        <div class="legend"><span class="marker marker-vsr"></span><span class="text">리스크</span></div>
        <div class="legend"><span class="marker marker-vsb"></span><span class="text">글로벌</span></div>
        <div class="legend"><span class="marker" style="background-color: #a4a3a3"></span><span class="text">없음</span></div>
      </div>
      <!-- chart-details -->
      <div class="chart-details">
        <div class="chart-rank">
          <div class="kb-table kb-table-bordered">
            <table>
              <colgroup>
                <col style="width:auto;">
                <col style="width:auto;">
              </colgroup>
              <tbody>
              <tr>
                <td><strong class="td-text">목표설정</strong></td>
                <td class="text-end"><strong class="td-text text-muted">{{ hopGoals.length }}명</strong></td>
              </tr>
              <tr>
                <td><strong class="td-text">목표미설정</strong></td>
                <td class="text-end"><strong class="td-text text-red">{{abilities.length - hopGoals.length}}명</strong></td>
              </tr>
              <tr>
                <td><strong class="td-text">목표설정률</strong></td>
                <td class="text-end"><strong class="td-text text-muted">{{ abilities.length > 0 ? (hopGoals.length / abilities.length * 100).toFixed(0) : 0 }}%</strong></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>
<script>

import ToastNestedPieChart from '@/components/chart/ToastNestedPieChart';
import {viceBestSetup} from '@/assets/js/modules/mypage/vice/vice-setup';

export default {
  name: "MobileViceTalentBest",
  components: {ToastNestedPieChart},
  props: {
    abilities: Array,
    goals: Array,
  },
  setup: viceBestSetup
}
</script>
