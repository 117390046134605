<template>
  <div class="content-scrollwrap d-block">
    <div class="kb-table kb-table-bordered kb-table-padding kb-table-equal-padding" style="min-width:1070px">
      <table>
        <colgroup>
          <col style="width:102px;">
          <col>
          <col>
          <col>
          <col>
          <col>
          <col>
          <col>
          <col>
          <col>
          <col>
<!--          <col>-->
          <col style="width:52px;">
        </colgroup>
        <thead ref="headerDom">
        <tr>
          <th class="px-0" rowspan="3"><span class="th-title">구분</span></th>
          <th rowspan="2" colspan="2"><span class="th-title">HRD 클라우드 <br/>마일리지<br>(1일 최대 2마일리지)</span></th>
          <th colspan="8"><span class="th-title">SSL 프로그램 학습활동</span></th>
          <th class="px-2" rowspan="3"><span class="th-title">합계</span></th>
        </tr>
        <tr>
          <th colspan="5" class="cell-border"><span class="th-title">학습노트 작성 <br>(건당 5점)</span></th>
          <th rowspan="2"><span class="th-title">그룹러닝 <br/>학습모임<br>(회당 5점)</span></th>
          <th class="px-2" rowspan="2"><span class="th-title">지식e <br/>진단평가<br>(60점 이상<br>1회 10점)</span></th>
          <th class="px-2" rowspan="2"><span class="th-title">SSL 소계</span></th>
        </tr>
        <tr>
          <th class="cell-border"><span class="th-title">직무</span></th>
          <th><span class="th-title">직무외</span></th>
          <th><span class="th-title">셀프러닝</span></th>
          <th><span class="th-title">그룹러닝</span></th>
          <th><span class="th-title">지식e<br>러닝</span></th>
          <th><span class="th-title">HRD<br>클라우드 <br>러닝</span></th>
          <th><span class="th-title">북러닝</span></th>
        </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in renderAbilities" :key="index">
            <td class="px-0"><div class="td-cell"><span class="td-text">{{ item.lrnerNm }}</span></div></td>
            <td><span class="td-text">{{ item.onMlg }}</span></td>
            <td><span class="td-text">{{ item.offMlg }}</span></td>
            <td v-for="(s,idx) in item.ssl" :key="`${index}-${idx}`"><span class="td-text">{{s}}건 <br>({{ (s * 5) }}점)</span></td>
            <td><span class="td-text" v-html="item.groupScore > 0 ? `${item.groupScore.toFixed(0)}회<br>(${(item.groupScore * 5).toFixed(0)}점)` : '0회<br>(0점)'"></span></td>
            <td><span class="td-text">{{ item.knowledgeScore }}점</span></td>
            <td><span class="td-text">{{ item.sslTotalScore }}점</span></td>
<!--            <td><span class="td-text">-</span></td>-->
            <td><span class="td-text">{{ item.totalScore }}</span></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>

import {viceAbilitySelfTableSetup} from '@/assets/js/modules/mypage/vice/vice-setup';

export default {
  name: "MobileViceTalentAbilitySelfTable",
  components: {},
  props: {
    targetAbilities: Array,
    selfs: Array,
    hrdMlgs: Array,
    knowledges: Array,
    groups: Array,
  },
  setup: viceAbilitySelfTableSetup
}
</script>
