<template>
  <div class="main-content">
    <MobileViceTalentBest
        v-if="readies.ability && readies.goal"
        :abilities="abilities"
        :goals="goals"
    />
    <MobileViceTalentAbility
        v-if="readies.ability"
        :abilities="abilities"
        :goals="goals"
        :all-abilities="allAbilities"
        :selfs="selfs"
        @showDetail="showDetail"
    />
    <MobileViceTalentSSL
        v-if="readies.self && readies.hrd && readies.group"
        :write="write"
        :grape="grape"
        :etc-grape="etcGrape"
        :hrd-mlgs="hrdMlgs"
        :knowledges="knowledges"
        :groups="groups"
        :abilities="abilities"
        :total-readies="totalReadies"
    />

  </div>
</template>
<script>

import MobileViceTalentBest from '@/components/mypage/vice/mobile/status/MobileViceTalentBest';
import MobileViceTalentAbility from '@/components/mypage/vice/mobile/status/MobileViceTalentAbility';
import MobileViceTalentSSL from '@/components/mypage/vice/mobile/status/MobileViceTalentSSL';

export default {
  name: "MobileViceTalentStatus",
  components: {MobileViceTalentSSL, MobileViceTalentAbility, MobileViceTalentBest},
  props: {
    abilities: Array,
    goals: Array,
    selfs: Array,
    allAbilities: Array,
    write: Object,
    grape: Object,
    etcGrape: Object,
    readies: Object,
    totalReadies: Object,
    hrdMlgs: Array,
    knowledges: Array,
    groups: Array
  },
  emits: ['showDetail'],
  setup(props, {emit}) {
    const showDetail = target => {emit('showDetail', target);}
    return {
      showDetail
    }
  }
}
</script>
