<template>
  <main class="kb-main" id="kb-talentboard-admin">
    <!-- main-content -->
    <div class="main-content min-component">
      <div class="coaching-view-container">
        <!-- 학습자 정보 -->
        <div class="learner-summary">
          <div class="learner-avatar">
            <div class="image">
              <ProfileImg
                  :target="target"
              />
            </div>
          </div>
          <div class="learner-header">
            <h3 class="title">{{ target.lrnerNm }} ・ {{ target.jbgdCd }}</h3>
          </div>
          <div class="learner-meta">
            <span class="meta">{{ target.deptNm }}</span>
            <span v-if="target.curtJbgdPromoYmd" class="meta">{{ `${target.curtJbgdPromoYmd.substr(0, 4)}` }}</span>
            <span class="meta">희망역할 {{ hopeGoal.crseWorkDtlNm }}</span>
            <span v-if="target.bestAbility.crseWorkDtlNm" class="meta">우수역할 {{ target.bestAbility.crseWorkDtlNm }}</span>
          </div>
        </div>
        <!-- content-section:학습자 정보 -->
        <section class="content-section">
          <header class="section-header">
            <h3 class="title">학습자 정보</h3>
          </header>
          <div class="kb-form-fields">
            <!-- kb-form-item -->
            <div class="kb-form-item">
              <div class="kb-form-row">
                <label class="kb-form-label"><span class="kb-form-label-text">현재 학습분야</span></label>
              </div>
              <div class="kb-form-row">
                <div class="kb-form-text">{{ nowGoal.crseWorkDtlNm }}</div>
              </div>
            </div>
            <!-- kb-form-item -->
            <div class="kb-form-item">
              <div class="kb-form-row">
                <label class="kb-form-label"><span class="kb-form-label-text">희망 학습분야</span></label>
              </div>
              <div class="kb-form-row">
                <div class="kb-form-text">{{ hopeGoal.crseWorkDtlNm }}</div>
              </div>
            </div>
            <!-- kb-form-item -->
            <div class="kb-form-item">
              <div class="kb-form-row">
                <label class="kb-form-label"><span class="kb-form-label-text">희망레벨</span></label>
              </div>
              <div class="kb-form-row">
                <div class="kb-form-text">전문가(Expert)</div>
              </div>
            </div>
            <!-- kb-form-item -->
            <div class="kb-form-item">
              <div class="kb-form-row">
                <label class="kb-form-label"><span class="kb-form-label-text">목표기한</span></label>
              </div>
              <div class="kb-form-row">
                <div class="kb-form-text">{{ hopeGoal.goalsEndYmd ? hopeGoal.goalsEndYmd.replace('-', '.').replace('-', '.') : '-' }}</div>
              </div>
            </div>
            <!-- kb-form-item -->
            <div class="kb-form-item">
              <div class="kb-form-row">
                <label class="kb-form-label"><span class="kb-form-label-text">취득희망자격증</span></label>
              </div>
              <div class="kb-form-row">
                <div class="kb-form-text">{{ hopeGoal.licenses && hopeGoal.licenses.length > 0 ? hopeGoal.licenses.map(x => x.lcsnNm).join(' ・ ') : '-'}}</div>
              </div>
            </div>
            <!-- kb-form-item -->
            <div class="kb-form-item">
              <div class="kb-form-row">
                <label class="kb-form-label"><span class="kb-form-label-text">목표</span></label>
              </div>
              <div class="kb-form-row d-block">
                <div class="kb-form-text">{{ hopeGoal.goalsCn }}</div>
              </div>
            </div>
          </div>
          <div v-if="hopeGoal.crseWorkDtlSn > 0" class="kb-form-fields-bottom">
            <span class="text">{{ timestampToDateFormat(hopeGoal.mdfcnDt, 'yyyy.MM.dd hh:mm') }} 등록</span>
          </div>
        </section>
        <!-- content-section:코칭 -->
        <template v-if="isReady">
          <section v-if="session.deptCd === target.deptCd" class="content-section">
            <header class="section-header">
              <h3 class="title">코칭등록</h3>
            </header>
            <div class="kb-form-fields">
              <div class="kb-form-field">
                <div class="kb-form-row">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">코칭</span>
                    <span class="kb-form-byte-text">{{ coachingCnLen }} /300</span>
                  </label>
                </div>
                <div class="kb-form-row">
                  <textarea v-model.trim="coachingCn" class="kb-form-control" placeholder="목표를 확인 후 코칭을 등록해주세요."></textarea>
                </div>
              </div>
            </div>
          </section>

          <section v-else-if="coaching.crseWorkGoalsSn > 0" class="content-section">
            <header class="section-header">
              <h3 class="title">코칭</h3>
            </header>
            <div class="textbox">
              <p class="text">{{ coaching.goalsCn }}</p>
            </div>
            <div class="textbox-bottom"><span class="text"><em>{{ coaching.mdfrNm }}({{ coaching.mdfrId }})</em>  {{ timestampToDateFormat(coaching.mdfcnDt, 'yyyy.MM.dd hh:mm') }}</span></div>
          </section>

          <div v-if="isReady && session.deptCd === target.deptCd" class="page-buttons">
            <button class="kb-btn kb-btn-primary" @click="clickSaveCoaching">{{ coaching.crseWorkGoalsSn === 0 ? '저장' : '수정' }}하기</button>
          </div>
        </template>
      </div>
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>
import ProfileImg from '@/components/common/ProfileImg';
import {viceTalentTargetCoachingSetup} from '@/assets/js/modules/mypage/vice/vice-setup';

export default {
  name: 'MobileViceTalentTargetCoaching',
  components: {ProfileImg},
  props: {
    target: Object
  },
  emits: ['completed'],
  setup: viceTalentTargetCoachingSetup
};
</script>

