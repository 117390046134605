<template>
  <div class="board-list-container board-list-bordered">
    <ul class="board-list">
      <!-- board-list-item -->
      <li  v-for="(item, index) in renderAbilities" :key="index" class="board-list-item">
        <a href="javascript:" class="board-link"></a>
        <div class="list-content">
          <div class="list-content-title">
            <h5 class="title">
              <span class="text text-truncate">{{ item.lrnerNm }} ・ {{item.lrnerId}} ・ {{ item.deptNm }} ・ {{ item.jbgdCd }} | {{ item.curtJbgdPromoYmd && item.curtJbgdPromoYmd.length > 3 ? item.curtJbgdPromoYmd.substr(0, 4) : '-'}}</span>
            </h5>
          </div>
          <div class="list-meta-content">
            <span class="text">희망역할 {{ item.hopeAbility.crseWorkDtlNm ? item.hopeAbility.crseWorkDtlNm : '-' }}</span>
            <span class="text">우수역할 {{ item.bestAbility.crseWorkDtlNm ? item.bestAbility.crseWorkDtlNm : '-' }}</span>
            <span class="text">비고 {{ item.etc }}</span>
          </div>
        </div>
      </li>
      <!-- board-list-item -->
    </ul>
  </div>
</template>
<script>

export default {
  name: "MobileViceTalentAbilityProfileTable",
  components: {},
  props: {
    renderAbilities: Array,
    paging: Object
  },
  setup() {

  }
}
</script>
