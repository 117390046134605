<template>
  <main class="kb-main" id="kb-talentboard-admin">
    <div class="main-header">
      <!-- main-header > header-snb -->
      <div class="header-snb">
        <nav class="snb">
          <ul class="snb-list">
            <li v-for="(tab, index) in view.tabs" class="snb-item" :class="{'is-active': view.current === tab.key}" :key="index">
              <button @click="view.current = tab.key">{{ tab.name }}</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <!-- main-content -->
    <div class="main-content">
      <div class="profile-list-container">
        <section class="content-section">
          <header class="section-header">
            <h3 class="title font-bodyB">{{ currentTab.name }}</h3>
          </header>
          <div class="list-top list-top-shrink">
            <div class="top-column">
              <p class="text text-muted">전체 {{ targetAbilities.length }}건</p>
            </div>
          </div>


          <MobileViceTalentAbilityProfileTable
              v-if="view.current === 'profile'"
              :render-abilities="pagingAbilities"
              :paging="paging"
          />
          <MobileViceTalentAbilityAceTable
              v-else-if="view.current === 'ace'"
              :target-abilities="pagingAbilities"
              :paging="paging"
          />
          <MobileViceTalentAbilitySelfTable
              v-else-if="view.current === 'self'"
              ref="selfDom"
              :target-abilities="pagingAbilities"
              :selfs="selfs"
              :hrd-mlgs="hrdMlgs"
              :groups="groups"
              :knowledges="knowledges"
          />
        </section>

        <div style="margin-top: 8px;">
          <CommonPaginationFront :paging="{
                                    pageNo: paging.pageNo,
                                    pageSize: paging.pageSize,
                                    totalCount: (targetAbilities.length)
                                  }"
                                 :page-func="pagingFunc"
                                 :page-list-size="5"
          />
        </div>
      </div>
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>
import MobileViceTalentAbilityProfileTable
  from '@/components/mypage/vice/mobile/status/table/MobileViceTalentAbilityProfileTable';
import MobileViceTalentAbilityAceTable
  from '@/components/mypage/vice/mobile/status/table/MobileViceTalentAbilityAceTable';
import MobileViceTalentAbilitySelfTable
  from '@/components/mypage/vice/mobile/status/table/MobileViceTalentAbilitySelfTable';
import {viceTargetAbilitySetup} from '@/assets/js/modules/mypage/vice/vice-setup';
import CommonPaginationFront from '@/components/CommonPaginationFront';

export default {
  name: 'MobileViceTalentTargetAbility',
  components: {
    CommonPaginationFront,
    MobileViceTalentAbilitySelfTable,
    MobileViceTalentAbilityAceTable, MobileViceTalentAbilityProfileTable},
  props: {
    targetAbilities: Array,
    goals: Array,
    selfs: Array,
    hrdMlgs: Array,
    knowledges: Array,
    groups: Array,
  },
  setup: viceTargetAbilitySetup
};
</script>

