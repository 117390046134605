<template>
  <LxpMobileHeader :use-route-name="(!openDetail && !openCoaching)" :title="modalTitle" >
    <template v-slot:left>
      <div v-if="openDetail || openCoaching" class="util util-back">
        <a href="javascript:" class="util-actions util-actions-back" @click="hideModal">
          <i class="icon-history-back"></i>
        </a>
      </div>
    </template>
  </LxpMobileHeader>

  <MobileViceTalentTargetAbility
      v-if="openDetail"
      :goals="goals"
      :selfs="selfs"
      :target-abilities="targetAbilities"
      :hrd-mlgs="hrdMlgs"
      :groups="groups"
      :knowledges="knowledges"
  />
  <MobileViceTalentTargetCoaching
      v-else-if="openCoaching"
      :target="targetAbility"
      @completed="completedCoaching"
  />
  <!-- begin::kb-main -->
  <main v-else class="kb-main" id="kb-talentboard-admin" @click="closeDeptToggles(-1)">
    <!-- main-header -->
    <MobileViceTalentRoute
        v-model:link="link"
        :dept="dept"
        :depts="depts"
        @closeToggles="closeDeptToggles"
        @selectedDept="selectedDept"
    />
    <template v-if="readies.ability && readies.goal && readies.all">
      <MobileViceTalentStatus
          v-if="link === 'status'"
          :abilities="abilities"
          :goals="goals"
          :selfs="selfs"
          :all-abilities="allAbilities"
          :total-readies="totalReadies"
          :write="write"
          :grape="grape"
          :etc-grape="etcGrape"
          :readies="readies"
          :hrd-mlgs="hrdMlgs"
          :knowledges="knowledges"
          :groups="groups"
          @showDetail="showDetail"
      />
      <MobileViceTalentCoaching
          v-else-if="link === 'coaching'"
          :abilities="abilities"
          :goals="goals"
          @showCoaching="showCoaching"
      />
    </template>
  </main>
  <!-- end::kb-main -->
</template>

<script>
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import MobileViceTalentRoute from '@/components/mypage/vice/mobile/MobileViceTalentRoute';
import MobileViceTalentStatus from '@/components/mypage/vice/mobile/MobileViceTalentStatus';
import {viceMainSetup} from '@/assets/js/modules/mypage/vice/vice-setup';
import MobileViceTalentTargetAbility from '@/components/mypage/vice/mobile/status/MobileViceTalentTargetAbility';
import MobileViceTalentCoaching from '@/components/mypage/vice/mobile/MobileViceTalentCoaching';
import MobileViceTalentTargetCoaching from '@/components/mypage/vice/mobile/coaching/MobileViceTalentTargetCoaching';

export default {
  name: 'MobileMyViceTalentBoard',
  components: {
    MobileViceTalentTargetCoaching,
    MobileViceTalentCoaching,
    MobileViceTalentTargetAbility, MobileViceTalentStatus, MobileViceTalentRoute, LxpMobileHeader},
  setup: viceMainSetup
};
</script>
