<template>
  <article class="content-section content-section-card section-02">
    <header class="section-header">
      <h3 class="title">
        역량개발 학습지수 현황
<!--        역량개발지수 <span class="text text-muted">KB ACE Academy</span>-->
        <button class="tool-tip-button" @click="tooltip = !tooltip"><img src="@/assets/lxp/images/common/ic_tooltip.svg" alt=""></button>
      </h3>
    </header>
    <div class="tool-tip" :class="{'is-active': tooltip}" style="top:-20px">
      <div class="tool-tip-desc">
        소속부점 직원들의 학습분야별/수준별 ‘인원’을 표시합니다. ‘인원’을 클릭하면 개인별 역량개발 상세현황을 확인할 수 있습니다.<br>
        상세현황은 ❶ 프로필, ❷ 역량개발 학습지수 ❸ 자기주도 학습지수로 구분하여 확인 가능합니다.
      </div>
    </div>
    <div class="chart-legends">
      <div class="legend"><span class="marker marker-red"></span><span class="text">미흡</span></div>
      <div class="legend"><span class="marker marker-silver"></span><span class="text">보통</span></div>
      <div class="legend"><span class="marker marker-yellow"></span><span class="text">우수</span></div>
    </div>
    <div class="content-scrollwrap">
      <div class="kb-table kb-table-bordered">
        <table>
          <colgroup>
            <col style="width:75px">
          </colgroup>
          <thead>
          <tr>
            <th><span class="th-title">&nbsp;</span></th>
            <th><span class="th-title">기업금융</span></th>
            <th><span class="th-title">자산관리</span></th>
            <th><span class="th-title">DT기획</span></th>
            <th><span class="th-title">DT개발</span></th>
            <th><span class="th-title">IB</span></th>
            <th><span class="th-title">자본시장</span></th>
            <th><span class="th-title">리스크</span></th>
            <th><span class="th-title">글로벌</span></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th><span class="th-title">비교</span></th>
            <td v-for="(grade, index) in abilityGrades" :key="index">
              <div class="td-cell"><div class="marker" :class="grade"></div></div>
            </td>
          </tr>
          <tr v-for="(level, index) in tableAbilities" :key="index">
            <th scope="row"><span class="td-text">{{ level.name }}</span></th>
            <td v-for="(item, idx) in level.items" :key="`${index}-${idx}`">
              <span class="td-text kb-mouse-cursor kb-hover-underline" @click="showDetail(item, index)">{{ item.count }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </article>

  <article class="content-section content-section-card section-03">
    <header class="section-header">
      <h3 class="title">역량개발 학습지수 분포차트</h3>
      <div class="header-side">
      </div>
    </header>
    <div class="chart-column">
      <div class="chart-legends">
        <div class="legend"><span class="marker marker-vso"></span><span class="text">무등급</span></div>
        <div class="legend"><span class="marker marker-yellow"></span><span class="text">초급</span></div>
        <div class="legend"><span class="marker marker-vsr"></span><span class="text">중급</span></div>
        <div class="legend"><span class="marker marker-vsb"></span><span class="text">고급</span></div>
        <div class="legend"><span class="marker marker-bb"></span><span class="text">전문가</span></div>
      </div>
      <div class="content-scrollwrap">
        <div class="chart-wrap">
          <div class="chart-bar-series">
            <div v-for="(ability, index) in chartAbilities" class="chart-series" :key="index">
              <div class="series-path">
                <div v-for="(level, idx) in ability.items" class="value" :class="[level.color]" :style="{flex: `0 0 ${level.height}px`}" :key="`${index}-${idx}`"></div>
              </div>
            </div>
          </div>
          <div class="chart-yaxis">
            <div v-for="(item, index) in chartYAxiosItems" class="grid" :key="index">
              <span class="grid-text">{{ item }}</span>
            </div>
          </div>
          <div class="chart-xaxis">
            <span class="chart-xaxis-text">자산관리</span>
            <span class="chart-xaxis-text">기업금융</span>
            <span class="chart-xaxis-text">DT 기획</span>
            <span class="chart-xaxis-text">DT 개발</span>
            <span class="chart-xaxis-text">리스크</span>
            <span class="chart-xaxis-text">자본시장</span>
            <span class="chart-xaxis-text">IB</span>
            <span class="chart-xaxis-text">글로벌</span>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>
<script>

import {viceAbilitySetup} from '@/assets/js/modules/mypage/vice/vice-setup';

export default {
  name: "MobileViceTalentAbility",
  components: {},
  props: {
    abilities: Array,
    goals: Array,
    selfs: Array,
    allAbilities: Array
  },
  emits: ['showDetail'],
  setup: viceAbilitySetup
}
</script>
